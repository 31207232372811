import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import { makeProductShortDescription } from "../../../helpers/ConversionHelper";
import {
  showErrorMsg,
  showInfoMsg,
  showSuccessMsg,
  validateAnyFormField,
} from "../../../helpers/ValidationHelper";
import rootAction from "../../../stateManagment/actions/rootAction";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";

//--strip
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  formatPrice,
  GetDefaultCurrencyCode,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  isAppleBrowser,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import CheckoutStripForm from "../../components/cart/CheckoutStripForm";

//--Paypal
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import GlobalEnums from "../../../helpers/GlobalEnums";
import ApplePay from "./components/paybraintree/ApplePay";
import PayOs from "./components/payos/PayOs";

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRP_PUBLISHABLE_KEY);

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currency = useRef(GetDefaultCurrencyCode());
  const [showCardSectionStripe, setShowCardSectionStripe] = useState(false);
  const [showCardSectionPaypal, setShowCardSectionPaypal] = useState(false);
  const [showCardSectionPayOs, setShowCardSectionPayOs] = useState(false);
  const [showCardSectionApplePay, setShowCardSectionApplePay] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState({
    PaymentMethodID: 0,
    PaymentMethodName: "",
  });
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  const [OrderNote, setOrderNote] = useState("");
  const [CartSubTotal, setCartSubTotal] = useState(0);
  const [ShippingSubTotal, setShippingSubTotal] = useState(0);
  const [OrderTotal, setOrderTotal] = useState(0);
  const [OrderTotalAfterDiscount, setOrderTotalAfterDiscount] = useState(0);
  const [cartProductsData, setCartProductsData] = useState(0);
  const [CouponCode, setCouponCode] = useState("");
  const [IsCouponCodeApplied, setIsCouponCodeApplied] = useState(false);
  const [IsAlreadyDiscountApplied, setIsAlreadyDiscountApplied] =
    useState(false);
  const [CouponCodeCssClass, setCouponCodeCssClass] =
    useState("cart-coupon-code");
  const [paypalOrderID, setPaypalOrderID] = useState(false);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const [isGuest, setIsGuest] = useState(false);

  const [UserID, setUserID] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");

  const [EmailAddress, setEmailAddress] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [AddressLineOne, setAddressLineOne] = useState("");
  const [PostalCode, setPostalCode] = useState("");
  const [StateProvinceId, setStateProvinceId] = useState("");
  const [CityId, setCityId] = useState("");
  const [CountryID, setCountryID] = useState("");
  const [StatesProvincesList, setStatesProvincesList] = useState([]);
  const [CitiesList, setCitiesList] = useState([]);
  const [CountriesList, setCountriesList] = useState([]);
  const [popupWindow, setPopupWindow] = useState();

  const cartJsonDataSession = useSelector(
    (state) => state.cartReducer.cartItems
  );
  const [cartItemsSession, setCartItemsSession] = useState([]);

  // if (
  //   loginUser === undefined ||
  //   loginUser.UserID === undefined ||
  //   loginUser.UserID < 1
  // ) {
  //   // navigate("/" + getLanguageCodeFromSession() + "/login");
  //   setIsGuest(true);
  // }

  const HandleStateProvinceChagne = async (value) => {
    if (value !== undefined) {
      await setStateProvinceId(value);

      //--load city data when state changed
      await LoadCitiesData(value);
    }
  };
  const HandleCountryChange = async (value) => {
    if (value !== undefined) {
      await setCountryID(value);

      //--load state province data
      await LoadStateProvinceData(value);
    }
  };
  const LoadStateProvinceData = async (CountryValue) => {
    const headersStateProvince = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramStateProvince = {
      requestParameters: {
        CountryId: CountryValue ?? CountryID,
        recordValueJson: "[]",
      },
    };

    //--Get state province list
    const responseStatesProvince = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_STATES_PROVINCES_LIST"],
      null,
      paramStateProvince,
      headersStateProvince,
      "POST",
      true
    );
    if (
      responseStatesProvince !== null &&
      responseStatesProvince.data !== null
    ) {
      await setStatesProvincesList(
        JSON.parse(responseStatesProvince.data.data)
      );
    }
  };

  const LoadCitiesData = async (StateValue) => {
    let headersCity = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let paramCity = {
      requestParameters: {
        StateProvinceId: StateValue ?? StateProvinceId,
        recordValueJson: "[]",
      },
    };

    //--Get cities list
    let responseCities = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_CITIES_LIST"],
      null,
      paramCity,
      headersCity,
      "POST",
      true
    );
    if (responseCities !== null && responseCities.data !== null) {
      await setCitiesList(JSON.parse(responseCities.data.data));
    }
  };

  const GetCouponCodeInfo = async () => {
    if (IsCouponCodeApplied) {
      showInfoMsg("Coupon code is already applied!");
      return false;
    }

    let isValid = validateAnyFormField(
      "Coupon Code",
      CouponCode,
      "text",
      null,
      30,
      true
    );
    if (isValid === false) {
      return false;
    }

    const headersCoupon = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramCoupon = {
      requestParameters: {
        CouponCode: CouponCode,
        cartJsonData: JSON.stringify(cartItemsSession),
      },
    };

    const couponResponse = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_COUPON_CODE_DISCOUNT"],
      Config["COMMON_CONTROLLER_SUB_URL"],
      paramCoupon,
      headersCoupon,
      "POST",
      true
    );

    if (couponResponse !== null && couponResponse.data !== null) {
      let copounData = JSON.parse(couponResponse.data.data);
      console.log(copounData);
      if (
        copounData !== undefined &&
        copounData.DiscountValueAfterCouponAppliedWithQuantity !== undefined &&
        copounData.DiscountValueAfterCouponAppliedWithQuantity > 0
      ) {
        setOrderTotalAfterDiscount(
          OrderTotal - copounData.DiscountValueAfterCouponAppliedWithQuantity ??
            0
        );
        setIsCouponCodeApplied(true);
      } else {
        showErrorMsg("Invalid coupon code!");
      }
    }
  };

  const handleOpenPaymentPopup = () => {
    const orderInformation = {
      total: OrderTotal,
      Currency: currency.current,
      language: getLanguageCodeFromSession(),
      paymentMethodId: paymentSelected.PaymentMethodID,
      couponCode: CouponCode,
      orderNote: OrderNote,
    };

    localStorage.setItem(
      "v1shop_createOrder",
      JSON.stringify(orderInformation)
    );

    let left = window.screen.width / 2 - 300;
    let top = window.screen.height / 2 - 300;
    const HOST = window.location.protocol + "//" + window.location.host;
    const newWindow = window.open(
      HOST + "/create-order/verify",
      "Payment",
      `width=600,height=600, top=${top}, left=${left}`
    );

    setPopupWindow(newWindow);
  };

  const reOpenPaymentPopup = () => {
    if (!popupWindow?.closed) {
      popupWindow?.focus();
    } else {
      handleOpenPaymentPopup();
    }
  };

  const handleCheckoutOnSubmit = async (e) => {
    if (!paymentSelected.PaymentMethodID) {
      showInfoMsg("Please select payment method");
    }
    try {
      e.preventDefault();

      //-- First Disable all forms
      setShowCardSectionStripe(false);
      setShowCardSectionPaypal(false);
      setShowCardSectionPayOs(false);
      setShowCardSectionApplePay(false);

      if (paymentSelected.PaymentMethodName.toLowerCase().includes("credit")) {
        setShowCardSectionStripe(true);
      } else if (
        paymentSelected.PaymentMethodName.toLowerCase().includes("paypal")
      ) {
        setShowCardSectionPaypal(true);
      } else if (
        paymentSelected.PaymentMethodName.toLowerCase().includes("vietqr")
      ) {
        setShowCardSectionPayOs(true);
        handleOpenPaymentPopup();
      } else if (
        paymentSelected.PaymentMethodName.toLowerCase().includes("apple")
      ) {
        setShowCardSectionApplePay(true);
        handleOpenPaymentPopup();
      }
    } catch (err) {
      showErrorMsg("An error occurred. Please try again!");
      console.log(err.message);
      if (paymentSelected === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
        HandleStripCardModal();
        HandlePaypalCardModal();
      }

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  const PlaceAndConfirmCustomerOrder = async (
    StripPaymentToken,
    payPalOrderConfirmJson = "{}"
  ) => {
    try {
      const headersStrip = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const paramSrip = {
        requestParameters: {
          UserID: UserID,
          OrderNote: OrderNote,
          cartJsonData: JSON.stringify(cartItemsSession),
          CouponCode: IsCouponCodeApplied === true ? CouponCode : "",
          PaymentMethod: paymentSelected.PaymentMethodID,
          paymentToken: StripPaymentToken ?? "",
          payPalOrderConfirmJson: payPalOrderConfirmJson ?? "",
          recordValueJson: "[]",
        },
      };

      const stripServerResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["POST_CUSTOMER_ORDER"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramSrip,
        headersStrip,
        "POST",
        true
      );
      console.log(stripServerResponse);

      if (
        stripServerResponse !== null &&
        stripServerResponse.data !== null &&
        stripServerResponse.status === 200
      ) {
        let stripServerResponseDetail = stripServerResponse.data.data;
        console.log(stripServerResponseDetail);

        if (
          stripServerResponseDetail.responseMsg !== undefined &&
          stripServerResponseDetail.responseMsg === "Order Placed Successfully"
        ) {
          showSuccessMsg("Order Placed Successfully!");

          setTimeout(function () {
            navigate("/" + getLanguageCodeFromSession() + "/");

            //--clear customer cart
            dispatch(rootAction.cartAction.setCustomerCart("[]"));
            dispatch(rootAction.cartAction.SetTotalCartItems(0));
            localStorage.setItem("cartItems", "[]");
          }, 1000);
        } else {
          showErrorMsg("An error occurred. Please try again!");
        }
      } else {
        showErrorMsg("An error occurred. Please try again!");
      }

      if (paymentSelected === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
        HandleStripCardModal();
      } else if (
        paymentSelected === process.env.REACT_APP_PAYPAL_PAYMENT_METHOD
      ) {
        HandlePaypalCardModal();
      }
    } catch (err) {
      showErrorMsg("An error occurred. Please try again!");
      console.log(err.message);
      if (paymentSelected === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
        HandleStripCardModal();
      }

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  const HandleStripCardModal = () => {
    setShowCardSectionStripe(!showCardSectionStripe);
  };

  const HandlePaypalCardModal = () => {
    setShowCardSectionPaypal(!showCardSectionPaypal);
  };

  //--update profile
  const handleUpdateProfileForm = async (event) => {
    event.preventDefault();

    try {
      let isValid = false;
      let validationArray = [];

      isValid = validateAnyFormField(
        "First Name",
        FirstName,
        "text",
        null,
        200,
        true
      );
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      isValid = validateAnyFormField(
        "Last Name",
        LastName,
        "text",
        null,
        150,
        true
      );
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      isValid = validateAnyFormField(
        "Shipping address",
        AddressLineOne,
        "text",
        null,
        600,
        true
      );
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      //--check if any field is not valid
      if (validationArray !== null && validationArray.length > 0) {
        isValid = false;
        return false;
      } else {
        isValid = true;
      }

      if (isValid) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        if (isGuest) {
          const param = {
            firstName: FirstName,
            lastName: LastName,
            emailAddress: EmailAddress,
            countryId: CountryID,
            postalCode: PostalCode,
            phoneNumber: MobileNo,
            addressLineOne: AddressLineOne,
          };
          if (CountryID) param.countryId = Number(CountryID);
          if (CityId) param.cityId = Number(CityId);
          if (StateProvinceId) param.stateProvinceId = Number(StateProvinceId);
          //--make api call for data operation
          const response = await MakeApiCallAsync(
            Config.END_POINT_NAMES["REGISTER_GUEST_USER"],
            Config["SUB_URL"],
            param,
            headers,
            "POST",
            true
          );
          if (response !== null && response.data !== null) {
            if (response.data.data !== null && response.data.data !== 0) {
              setUserID(response.data.data);
              dispatch(rootAction.userAction.setGuestUser(response.data.data));
              handleCheckoutOnSubmit(event);
            } else {
              showErrorMsg(response.data.message);
              return false;
            }
          }
        } else {
          const param = {
            requestParameters: {
              UserID: UserID,
              FirstName: FirstName,
              LastName: LastName,
              MobileNo: MobileNo,
              AddressLineOne: AddressLineOne,
              CityId: CityId,
              StateProvinceId: StateProvinceId,
              PostalCode: PostalCode,
            },
          };
          //--make api call for data operation
          const response = await MakeApiCallAsync(
            Config.END_POINT_NAMES["UPDATE_PROFILE"],
            null,
            param,
            headers,
            "POST",
            true
          );
          if (response !== null && response.data !== null) {
            let userData = JSON.parse(response.data.data);
            if (
              userData.length > 0 &&
              userData[0].ResponseMsg !== undefined &&
              userData[0].ResponseMsg == "Saved Successfully"
            ) {
              handleCheckoutOnSubmit(event);

              loginUser.FirstName = FirstName;
              loginUser.LastName = LastName;
              loginUser.MobileNo = MobileNo;
              loginUser.AddressLineOne = AddressLineOne;
              loginUser.CityId = CityId;
              loginUser.StateProvinceId = StateProvinceId;
              loginUser.PostalCode = PostalCode;

              //--save user data in session
              localStorage.setItem("user", JSON.stringify(loginUser));
              dispatch(
                rootAction.userAction.setUser(JSON.stringify(loginUser))
              );
            } else if (
              userData.length > 0 &&
              userData[0].ResponseMsg !== undefined &&
              userData[0].ResponseMsg == "Email already exists!"
            ) {
              showErrorMsg("Email already exists!");
              return false;
            } else {
              showErrorMsg("An error occurred. Please try again!");
              return false;
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
      showErrorMsg("An error occurred. Please try again!");

      return false;
    } finally {
      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };
  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              //currency_code: "USD",
              currency_code: GetDefaultCurrencyCode(),
              value:
                OrderTotalAfterDiscount !== undefined &&
                OrderTotalAfterDiscount > 0
                  ? OrderTotalAfterDiscount
                  : OrderTotal,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((paypalOrderID) => {
        setPaypalOrderID(paypalOrderID);
        return paypalOrderID;
      });
  };

  // check paypal Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      //setSuccess(true); replace with your own

      //-- Set paypal json response if approve
      let JsonDetail = JSON.stringify(details);

      //--start loader
      dispatch(rootAction.commonAction.setLoading(true));

      setTimeout(() => {
        PlaceAndConfirmCustomerOrder(null, JsonDetail);
      }, 500);

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);

      HandlePaypalCardModal();
    });
  };

  //capture likely error for paypal
  const onError = (data, actions) => {
    HandlePaypalCardModal();
    showErrorMsg("An error occurred. Please try again!");
  };

  //get payment methods in order
  const GetPaymentMethods = useCallback(async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const param = {
      requestParameters: {
        recordValueJson: "[]",
        Currency: currency.current,
      },
    };

    //--Get payment methods
    const responsePaymentMethods = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_PAYMENT_METHODS_IN_ORDER"],
      null,
      param,
      headers,
      "POST",
      true
    );

    return responsePaymentMethods;
  }, [currency]);

  //get cart
  useEffect(() => {
    let cartItems = JSON.parse(cartJsonDataSession ?? "[]");
    console.log(cartItems);

    if (
      cartItems.filter((obj) => obj.Currency == currency.current).length > 0
    ) {
      setCartItemsSession(cartItems);
    } else {
      let updatedCartItems = cartItems.map((obj) => {
        return { ...obj, Currency: currency.current };
      });
      setCartItemsSession(updatedCartItems);
    }
  }, [cartJsonDataSession, currency]);

  //get user
  useEffect(() => {
    if (
      loginUser === undefined ||
      loginUser.UserID === undefined ||
      loginUser.UserID < 1
    ) {
      setIsGuest(true);
    } else {
      setIsGuest(false);
      setUserID(loginUser.UserID);
      setFirstName(loginUser.FirstName);
      setLastName(loginUser.LastName);
      setMobileNo(loginUser.MobileNo);
      setAddressLineOne(loginUser.AddressLineOne);
      setStateProvinceId(loginUser.StateProvinceID);
      setCityId(loginUser.CityID);
      setPostalCode(loginUser.PostalCode);
    }
  }, [loginUser]);

  //get countries
  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const paramCountry = {
        requestParameters: {
          recordValueJson: "[]",
        },
      };

      //--Get countreis list
      const responseCountries = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_COUNTRIES_LIST"],
        null,
        paramCountry,
        headers,
        "POST",
        true
      );
      if (responseCountries !== null && responseCountries.data !== null) {
        await setCountriesList(JSON.parse(responseCountries.data.data));
      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["Signup"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);
  //get cities
  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {
      let headersCity = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      let paramCity = {
        requestParameters: {
          StateProvinceId: loginUser.StateProvinceID ?? 0,
          recordValueJson: "[]",
        },
      };

      //--Get cities list
      let responseCities = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_CITIES_LIST"],
        null,
        paramCity,
        headersCity,
        "POST",
        true
      );
      if (responseCities !== null && responseCities.data !== null) {
        await setCitiesList(JSON.parse(responseCities.data.data));
      }
      await setCityId(loginUser.CityID);

      let headersStateProvince = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      let paramStateProvince = {
        requestParameters: {
          CountryId: loginUser.CountryID,
          recordValueJson: "[]",
        },
      };

      //--Get state province list
      let responseStatesProvince = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_STATES_PROVINCES_LIST"],
        null,
        paramStateProvince,
        headersStateProvince,
        "POST",
        true
      );
      if (
        responseStatesProvince !== null &&
        responseStatesProvince.data !== null
      ) {
        await setStatesProvincesList(
          JSON.parse(responseStatesProvince.data.data)
        );
      }
      await setStateProvinceId(loginUser.StateProvinceID);

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["UpdateProfile"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, [
    dispatch,
    loginUser.CityID,
    loginUser.CountryID,
    loginUser.StateProvinceID,
  ]);

  //get cart
  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      //--Get cart data
      const paramCart = {
        requestParameters: {
          cartJsonData: JSON.stringify(cartItemsSession),
          recordValueJson: "[]",
        },
      };

      const customerCartResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_CUSTOMER_CART_ITEMS"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramCart,
        headers,
        "POST",
        true
      );
      if (customerCartResponse !== null && customerCartResponse.data !== null) {
        let finalData = JSON.parse(customerCartResponse.data.data);
        // console.log(finalData);

        if (finalData !== null) {
          setTimeout(() => {
            setCartProductsData(finalData.productsData);
            setCartSubTotal(finalData.cartSubTotal);
            setShippingSubTotal(finalData.shippingSubTotal);
            setOrderTotal(finalData.orderTotal);

            if (
              finalData.productsData.length > 0 &&
              finalData.productsData.some((el) => el.DiscountedPrice > 0)
            ) {
              setIsAlreadyDiscountApplied(true);
            }
          }, 500);
        }
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    if (cartItemsSession && cartItemsSession.length > 0) {
      dataOperationInUseEffect().catch(console.error);
    }
    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, [cartItemsSession, dispatch]);
  //payment
  useEffect(() => {
    let timer = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        if (window.isPaymentSuccess) {
          showSuccessMsg("Order Placed Successfully!");

          setTimeout(function () {
            navigate("/" + getLanguageCodeFromSession() + "/");

            //--clear customer cart
            dispatch(rootAction.cartAction.setCustomerCart("[]"));
            dispatch(rootAction.cartAction.SetTotalCartItems(0));
            localStorage.setItem("cartItems", "[]");
          }, 1000);
        } else if (window.isPaymentCancel || window.isPaymentFalse) {
          toast.error("Payment cancelled");
          window.location.reload();
        }
      }
    }, 2000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupWindow]);

  //get payment methods
  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["Checkout"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    // call the function
    dataOperationFunc().catch(console.error);

    const getPaymentMethods = async () => {
      const responsePaymentMethods = await GetPaymentMethods();
      // console.log(JSON.parse(responsePaymentMethods.data.data));

      setPaymentMethodList(JSON.parse(responsePaymentMethods.data.data));
      console.log(
        "paymentMethod",
        JSON.parse(responsePaymentMethods.data.data)[0]
      );

      setPaymentSelected(JSON.parse(responsePaymentMethods.data.data)[0]);
    };

    getPaymentMethods();
  }, [GetPaymentMethods]);

  useEffect(() => {
    if (cartJsonDataSession === "[]") {
      // toast.info("Cart is empty");
      navigate("/" + getLanguageCodeFromSession() + "/cart");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const renderPaymentItems = useCallback((item, index) => {
  //   if (
  //     item.PaymentMethodName.toLowerCase().includes("apple") &&
  //     isAppleBrowser()
  //   ) {
  //     return (
  //       <li key={index}>
  //         <div className="radio-option">
  //           <input
  //             type="radio"
  //             name="payment-group"
  //             id={item.PaymentMethodName}
  //             onClick={() => setPaymentSelected(item)}
  //           />
  //           <label htmlFor={item.PaymentMethodName}>
  //             {item.PaymentMethodName}
  //           </label>
  //         </div>
  //       </li>
  //     );
  //   }
  //   if (!item.PaymentMethodName.toLowerCase().includes("apple")) {
  //     return (
  //       <li key={index}>
  //         <div className="radio-option">
  //           <input
  //             type="radio"
  //             name="payment-group"
  //             id={item.PaymentMethodName}
  //             onClick={() => {
  //               setPaymentSelected(item);
  //               // console.log(item);
  //             }}
  //           />
  //           <label htmlFor={item.PaymentMethodName}>
  //             {item.PaymentMethodName}
  //           </label>
  //         </div>
  //       </li>
  //     );
  //   }
  //   return null;
  // }, []);

  //render checkout info
  const renderCheckoutInfo = useCallback(() => {
    return (
      <div className="checkout-info">
        <div className="order-summary">
          <h4>Order Summary</h4>
          <ul>
            {cartProductsData !== undefined &&
            cartProductsData !== null &&
            cartProductsData.length > 0
              ? cartProductsData?.map((data, idx) => (
                  <li>
                    <span style={{ flex: "2", textAlign: "left" }}>
                      {makeProductShortDescription(data.ProductName, 50)}
                    </span>
                    <span style={{ flex: "1" }}>x{data.Quantity}</span>
                    <span style={{ flex: "1", textAlign: "right" }}>
                      {data.DiscountedPrice !== undefined &&
                      data.DiscountedPrice > 0 ? (
                        <>
                          <del style={{ color: "#9494b9" }}>
                            {formatPrice(data.Price, data.Currency)}
                          </del>
                          &nbsp;
                          {formatPrice(data.DiscountedPrice, data.Currency)}
                        </>
                      ) : (
                        <>{formatPrice(data.Price, data.Currency)}</>
                      )}
                    </span>
                  </li>
                ))
              : null}
          </ul>
          <ul>
            <li>
              <span>Subtotal </span> <span>${OrderTotal}</span>
            </li>
            <li>
              <span>Shipping </span>
              <span>${ShippingSubTotal}</span>
            </li>
            <li>
              <span>Discount</span>{" "}
              <span>
                $
                {OrderTotalAfterDiscount
                  ? OrderTotal - OrderTotalAfterDiscount
                  : 0}
              </span>
            </li>
            <li>
              <span>Total</span>{" "}
              <span>
                $
                {OrderTotalAfterDiscount ? OrderTotalAfterDiscount : OrderTotal}
              </span>
            </li>
          </ul>
        </div>
        <div className="billing-summary">
          <h4>Billing Information</h4>

          <ul>
            <li>
              <span>Receiver </span> <span>{FirstName + " " + LastName}</span>
            </li>
            <li>
              <span>Phone </span>
              <span>{MobileNo ? MobileNo : "--"}</span>
            </li>
            <li>
              <span>Email</span>{" "}
              <span>
                {loginUser.EmailAddress ? loginUser.EmailAddress : EmailAddress}
              </span>
            </li>
            <li>
              <span>Address</span>{" "}
              <span>{AddressLineOne ? AddressLineOne : "--"}</span>
            </li>
            <li>
              <span>Note</span> <span>{OrderNote ? OrderNote : "--"}</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }, [
    cartProductsData,
    OrderTotal,
    OrderTotalAfterDiscount,
    ShippingSubTotal,
    FirstName,
    LastName,
    MobileNo,
    loginUser,
    AddressLineOne,
    OrderNote,
  ]);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Checkout</title>
        <meta name="description" content={siteTitle + " - Checkout"} />
        <meta name="keywords" content="Checkout"></meta>
      </Helmet>
      <SiteBreadcrumb title="Checkout" parent="Home" />

      <section>
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <div className="checkout-page">
            <div className="checkout-form">
              <Form
                onSubmit={
                  handleUpdateProfileForm // update profile -> checkout
                  // handleCheckoutOnSubmit
                }
              >
                <Row className="justify-content-center ">
                  <Col xs="12">
                    <div className="checkout-title">
                      <p>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Billing Details",
                              "lbl_check_billdetail"
                            )
                          : "Billing Details"}
                      </p>
                    </div>
                    <div className="theme-form pb-0">
                      <Row className="check-out">
                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "First Name",
                                  "lbl_check_fname"
                                )
                              : "First Name"}
                            {isGuest && (
                              <span className="required-field">*</span>
                            )}
                          </Label>

                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter first name"
                            id="FirstName"
                            name="FirstName"
                            required={true}
                            value={FirstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Last Name",
                                  "lbl_check_lname"
                                )
                              : "Last Name"}
                            {isGuest && (
                              <span className="required-field">*</span>
                            )}
                          </Label>

                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter last name"
                            id="LastName"
                            name="LastName"
                            required={true}
                            value={LastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Country",
                                  "lbl_check_country"
                                )
                              : "Country"}
                            {isGuest && (
                              <span className="required-field">*</span>
                            )}
                          </Label>
                          {isGuest ? (
                            <select
                              className="form-control"
                              name="CountryID"
                              id="CountryID"
                              required={true}
                              value={CountryID}
                              onChange={(e) =>
                                HandleCountryChange(e.target.value)
                              }
                            >
                              <option value="-999">Select Country</option>
                              {CountriesList?.map((item, idx) => (
                                <option value={item.CountryID}>
                                  {item.CountryName}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <input
                              type="text"
                              name="CountryName"
                              className="form-control"
                              readOnly
                              disabled
                              value={loginUser.CountryName}
                            />
                          )}
                        </FormGroup>

                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Email Address",
                                  "lbl_check_email"
                                )
                              : "Email Address"}

                            {isGuest && (
                              <span className="required-field">*</span>
                            )}
                          </Label>
                          {isGuest ? (
                            <Input
                              type="email"
                              className="form-control"
                              placeholder="Enter email"
                              id="EmailAddress"
                              name="EmailAddress"
                              required={true}
                              value={EmailAddress}
                              onChange={(e) => setEmailAddress(e.target.value)}
                            />
                          ) : (
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              disabled
                              readOnly
                              value={loginUser.EmailAddress}
                            />
                          )}
                        </FormGroup>
                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label htmlFor="CityId" className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "City",
                                  "lbl_uprofile_city"
                                )
                              : "City"}
                          </Label>

                          <select
                            className="form-control"
                            name="CityId"
                            id="CityId"
                            required={false}
                            value={CityId}
                            onChange={(e) => setCityId(e.target.value)}
                          >
                            <option value="-999">Select City</option>
                            {CitiesList?.map((item, idx) => (
                              <option value={item.CityID}>
                                {item.CityName}
                              </option>
                            ))}
                          </select>
                        </FormGroup>

                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label
                            htmlFor="StateProvinceId"
                            className="field-label"
                          >
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "State/Province",
                                  "lbl_uprofile_state"
                                )
                              : "State/Province"}
                          </Label>

                          <select
                            className="form-control"
                            name="StateProvinceId"
                            id="StateProvinceId"
                            required={false}
                            value={StateProvinceId}
                            onChange={(e) =>
                              HandleStateProvinceChagne(e.target.value)
                            }
                          >
                            <option value="-999">Select State</option>
                            {StatesProvincesList?.map((item, idx) => (
                              <option value={item.StateProvinceID}>
                                {item.StateName}
                              </option>
                            ))}
                          </select>
                        </FormGroup>

                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Postcode / Zip",
                                  "lbl_check_postcode"
                                )
                              : "Postcode / Zip"}
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="Enter postal code"
                            id="PostalCode"
                            name="PostalCode"
                            value={PostalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Phone",
                                  "lbl_check_phone"
                                )
                              : "Phone"}
                          </Label>

                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter phone number"
                            id="MobileNo"
                            name="MobileNo"
                            value={MobileNo}
                            onChange={(e) => setMobileNo(e.target.value)}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-12 col-sm-12 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Shipping Address",
                                  "lbl_check_shipadrs"
                                )
                              : "Shipping Address"}
                            {isGuest && (
                              <span className="required-field">*</span>
                            )}
                          </Label>

                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter shipping address"
                            id="AddressLineOne"
                            name="AddressLineOne"
                            required={true}
                            value={AddressLineOne}
                            onChange={(e) => setAddressLineOne(e.target.value)}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-12 col-sm-12 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Order Note",
                                  "lbl_check_phone"
                                )
                              : "Order Note"}
                          </Label>

                          <textarea
                            name="OrderNote"
                            id="OrderNote"
                            cols="30"
                            rows="6"
                            placeholder="Order Notes"
                            className="form-control"
                            value={OrderNote}
                            onChange={(e) => setOrderNote(e.target.value)}
                          />
                        </FormGroup>
                      </Row>
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className="checkout-details theme-form">
                      <div className="order-info-box">
                        {cartProductsData !== undefined &&
                        cartProductsData !== null &&
                        cartProductsData.length > 0 ? (
                          <div className="order-summary">
                            <div className="product-box">
                              <div className="title-box d-flex align-items-center justify-content-between">
                                <p className="name">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Product Name",
                                        "lbl_hdr_check_prdname"
                                      )
                                    : "Product Name"}
                                </p>
                                <p className="quantity">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Quantity",
                                        "lbl_hdr_check_qty"
                                      )
                                    : "Quantity"}
                                </p>
                                <p className="price">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Price",
                                        "lbl_hdr_check_price"
                                      )
                                    : "Price"}
                                </p>
                              </div>
                              {cartProductsData?.map((data, idx) => (
                                <div
                                  className="content-box d-flex align-items-center justify-content-between"
                                  key={idx}
                                >
                                  <p className="name">
                                    {makeProductShortDescription(
                                      data.ProductName,
                                      50
                                    )}
                                  </p>
                                  <p className="quantity">{data.Quantity}</p>
                                  <p className="price">
                                    {data.DiscountedPrice !== undefined &&
                                    data.DiscountedPrice > 0 ? (
                                      <>
                                        <del style={{ color: "#9494b9" }}>
                                          {formatPrice(
                                            data.Price,
                                            data.Currency
                                          )}
                                        </del>
                                        &nbsp;
                                        {formatPrice(
                                          data.DiscountedPrice,
                                          data.Currency
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {formatPrice(data.Price, data.Currency)}
                                      </>
                                    )}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="cart-subtotal d-flex justify-content-between">
                              <strong
                                id="lbl_check_cartsubtotal"
                                className="count"
                              >
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Cart Subtotal",
                                      "lbl_check_cartsubtotal"
                                    )
                                  : "Cart Subtotal"}
                              </strong>
                              <strong className="order-subtotal-amount">
                                {formatPrice(CartSubTotal, currency.current)}
                              </strong>
                            </div>
                            <div className="shipping-fee d-flex justify-content-between">
                              <span id="lbl_check_shipping">
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Shipping",
                                      "lbl_check_shipping"
                                    )
                                  : "Shipping"}
                              </span>
                              <span>
                                {formatPrice(
                                  ShippingSubTotal,
                                  currency.current
                                )}
                              </span>
                            </div>
                            <div className="cart-subtotal d-flex justify-content-between">
                              <input
                                type="text"
                                name="couponCode"
                                className={`form-control ${
                                  IsCouponCodeApplied ? CouponCodeCssClass : ""
                                }`}
                                placeholder="Enter Coupon Code"
                                value={CouponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                                maxLength={30}
                              />
                              <button
                                className="btn btn-normal btn-outline"
                                onClick={(e) => {
                                  e.preventDefault();
                                  GetCouponCodeInfo();
                                }}
                              >
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Apply Coupon",
                                      "lbl_check_applycpn"
                                    )
                                  : "Apply Coupon"}
                              </button>
                            </div>
                            <div className="cart-total d-flex justify-content-between gap-2">
                              <p>
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Order Total",
                                      "lbl_check_ordtotal"
                                    )
                                  : "Order Total"}
                              </p>
                              <p>
                                {OrderTotalAfterDiscount !== undefined &&
                                OrderTotalAfterDiscount > 0 ? (
                                  <>
                                    <del>
                                      {formatPrice(
                                        OrderTotal,
                                        currency.current
                                      )}
                                    </del>
                                    &nbsp; &nbsp;
                                    {formatPrice(
                                      OrderTotalAfterDiscount,
                                      currency.current
                                    )}
                                  </>
                                ) : (
                                  `${formatPrice(OrderTotal, currency.current)}`
                                )}
                              </p>
                            </div>
                          </div>
                        ) : null}

                        <div className="payment-box">
                          <div className="upper-box">
                            <div className="payment-options">
                              <ul>
                                {/* {paymentMethodList.length > 0 &&
                                paymentMethodList.map(renderPaymentItems)} */}
                                {paymentMethodList.map((item, index) => (
                                  <>
                                    <li
                                      key={index}
                                      className={
                                        paymentSelected.PaymentMethodID ===
                                        item.PaymentMethodID
                                          ? "active"
                                          : ""
                                      }
                                      onClick={() => {
                                        setPaymentSelected(item);
                                      }}
                                    >
                                      <div className="d-flex align-items-center justify-content-between w-100">
                                        <div className="radio-option">
                                          <input
                                            type="radio"
                                            name="payment-group"
                                            checked={
                                              paymentSelected.PaymentMethodID ===
                                              item.PaymentMethodID
                                            }
                                            id={item.PaymentMethodName}
                                            onClick={() => {
                                              setPaymentSelected(item);
                                            }}
                                          />
                                          <label
                                            htmlFor={item.PaymentMethodName}
                                          >
                                            {item.PaymentMethodName}
                                          </label>
                                        </div>
                                        <div>
                                          <img
                                            alt=""
                                            src={item.ImageUrl}
                                            height={40}
                                          />
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </div>
                          </div>

                          {cartProductsData !== undefined &&
                          cartProductsData !== null &&
                          cartProductsData.length > 0 ? (
                            paymentSelected.PaymentMethodName.toLowerCase().includes(
                              "paypal"
                            ) ? (
                              <div className="d-flex justify-content-end">
                                <div className="d-flex flex-column align-items-center gap-2">
                                  <button
                                    type="submit"
                                    className={"btn-normal btn btn-paypal"}
                                  >
                                    <span>
                                      Pay with{" "}
                                      <img
                                        src={"/images/paypal.png"}
                                        alt=""
                                        height={20}
                                      />
                                    </span>
                                  </button>
                                  {/* <img
                                        src={"/images/paypal.png"}
                                        alt=""
                                        height={32}
                                      /> */}
                                  <img
                                    src={"/images/card.png"}
                                    alt=""
                                    height={32}
                                  />
                                  <p>
                                    <i style={{ fontSize: "10px" }}>
                                      Powered by{" "}
                                    </i>
                                    <img
                                      src={"/images/paypal.png"}
                                      alt=""
                                      height={12}
                                    />
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className=" d-flex justify-content-end">
                                <button
                                  type="submit"
                                  className={"btn-normal btn"}
                                >
                                  Place Order
                                </button>
                              </div>
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </section>

      {/* <BestFacilities /> */}

      {/*Stripe card section starts here */}
      {showCardSectionStripe === true ? (
        <Modal
          isOpen={showCardSectionStripe}
          toggle={HandleStripCardModal}
          centered={true}
          size="lg"
          className="theme-modal"
          id="exampleModal"
          role="dialog"
          aria-hidden="true"
        >
          <ModalBody className="modal-content">
            <Button
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                e.preventDefault();
                HandleStripCardModal();
              }}
            >
              <span aria-hidden="true">×</span>
            </Button>
            <div className="news-latter">
              <div className="modal-bg">
                <div className="offer-content">
                  <div>
                    <h2>Card Details!</h2>
                    <p>
                      Provide your card detail
                      <br /> and confirm final order!
                    </p>

                    <Elements stripe={stripePromise}>
                      <CheckoutStripForm
                        UserID={loginUser.UserID}
                        OrderNote={OrderNote}
                        cartJsonData={JSON.stringify(cartItemsSession)}
                        ShippingSubTotal={ShippingSubTotal}
                        OrderTotal={OrderTotal}
                        OrderTotalAfterDiscount={OrderTotalAfterDiscount}
                        CouponCode={CouponCode}
                        HandleStripCardModal={HandleStripCardModal}
                        PlaceAndConfirmCustomerOrder={
                          PlaceAndConfirmCustomerOrder
                        }
                      />
                    </Elements>
                  </div>

                  {renderCheckoutInfo()}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      ) : (
        <></>
      )}

      {/* Stripe card section ends here */}

      {/* Paypal card section starts here */}

      {showCardSectionPaypal === true ? (
        <PayPalScriptProvider
          options={{
            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          }}
        >
          <Modal
            isOpen={showCardSectionPaypal}
            toggle={HandlePaypalCardModal}
            centered={true}
            size="lg"
            className="theme-modal"
            id="exampleModal"
            role="dialog"
            aria-hidden="true"
          >
            <ModalBody className="modal-content">
              <Button
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  HandlePaypalCardModal();
                }}
              >
                <span aria-hidden="true">×</span>
              </Button>
              <div className="news-latter">
                <div className="modal-bg">
                  <div className="offer-content">
                    <div>
                      <h2>PayPal Options!</h2>

                      <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                      />
                    </div>
                    {renderCheckoutInfo()}
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </PayPalScriptProvider>
      ) : (
        <></>
      )}

      {/* Paypal card section ends here */}

      {currency.current === "VND" && (
        <PayOs
          open={showCardSectionPayOs}
          close={() => setShowCardSectionPayOs(false)}
          onOpenPayment={reOpenPaymentPopup}
        />
      )}
      {isAppleBrowser() && (
        <ApplePay
          open={showCardSectionApplePay}
          close={() => setShowCardSectionApplePay(false)}
          onOpenPayment={reOpenPaymentPopup}
        />
      )}
    </>
  );
};

export default Checkout;
